import './style.scss';
import React, { memo } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BaseCard from '../../../../components/Card';
import BaseButton from '../../../../components/Button';
import HomeIcon4 from '../../../../assets/images/views/home/home-icon4.svg';
import HomeIcon6 from '../../../../assets/images/views/home/home-icon6.svg';
import HomeIcon5 from '../../../../assets/images/views/home/home-icon5.svg';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../../routes';

const Cards: React.FunctionComponent = () => {
  return (
    <section id="find">
      <Container className="cards-container">
        <Row className="cards-row">
          <Col sm={12}>
            <h2 className="find-scholarship-title">
              Encontrar la beca ideal es tan simple como:
            </h2>
            <div className="separator"></div>
          </Col>

          <Col sm={12} md={12} lg={4} xl={4}>
            <BaseCard className="crd">
              <img src={HomeIcon4} alt="Registro gratis" />
              <div className="card-content-container">
                <h3 className="home-cards-title">
                  Registrarte de forma gratuita.
                </h3>
                <p>
                  Hemos buscado todos los programas que existen en el mundo,
                  disponibles exclusivamente para ti.
                </p>
              </div>
            </BaseCard>
          </Col>

          <Col sm={12} md={12} lg={4} xl={4}>
            <BaseCard className="crd">
              <img src={HomeIcon5} alt="Pérfil academico" />
              <div className="card-content-container">
                <h3 className="home-cards-title">
                  Llenar las informaciones según tu perfil e intereses.
                </h3>
                <p>
                  Nosotros analizamos tu perfil y buscamos todas las opciones
                  que se ajusten a tu perfil, intereses y objetivos académicos.
                </p>
              </div>
            </BaseCard>
          </Col>

          <Col sm={12} md={12} lg={4} xl={4}>
            <BaseCard className="crd">
              <img src={HomeIcon6} alt="Oportunidades de becas" />

              <div className="card-content-container">
                <h3 className="home-cards-title">
                  Descubrir todas las oportunidades de becas disponibles para tu
                  perfil.
                </h3>
                <p>
                  Cuando descrubras todas las opciones que existen, entonces
                  estarás más preparado para aprovecharlas al máximo.
                </p>
              </div>
            </BaseCard>
          </Col>

          <Col sm={12}>
            <Link
              to={ROUTES.search}
              style={{ width: '100%' }}
              className="text-center"
            >
              <BaseButton style="pink" className="find-button">
                Iniciar mi búsqueda
              </BaseButton>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default memo(Cards);
