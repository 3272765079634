import './style.scss';
import React, { memo } from 'react';
import Container from 'react-bootstrap/Container';
import ComboBox from '../../components/ComboBox/index';
import PasswordInfo from '../../assets/images/commons/password-info.svg';
import RectangleBlue from '../../assets/icons/rectangle-blue.svg';
import RectangleYellow from '../../assets/icons/rectangle-yellow.svg';
import Title from '../../components/Title';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routes';

const ModifiedPassword: React.FunctionComponent = () => {
  return (
    <div className="password-container">
      <img
        src={RectangleBlue}
        className="overlay-rectangle-blue"
        alt="Cuadrado azul"
      />
      <img
        src={RectangleYellow}
        className="overlay-rectangle-yellow"
        alt="Cuadrado amarillo"
      />
      <ComboBox
        fluid={true}
        img={PasswordInfo}
        alt="Cambiar contraseña"
        imagePosition="right"
        leftColClassname="password-left"
        rightColClassname="password-right"
        leftColBreakpoints={[12, 6, 6]}
        rightColBreakPoints={[12, 6, 6]}
      >
        <div>
          <Title variant="h1">
            Tu contraseña ha sido <br /> modificada <br /> satisfactoriamente.
          </Title>

          <Link to={ROUTES.scholarships}>
            Ir a mi dashboard{' '}
            <FontAwesomeIcon className="ml-2 icon" icon={faArrowRight} />
          </Link>
        </div>
      </ComboBox>
    </div>
  );
};

export default memo(ModifiedPassword);
