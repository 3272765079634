import React from 'react';
import Subscribe from '../../components/ComboBox/Subscribe';
import Hero from './Components/Hero';
import Why from './Components/Why';
import Purple from './Components/PurpleSection';
import Cards from './Components/Cards';
import AboutUs from './Components/About';
import StartSearch from './Components/StartSearch';
import ScholarshipPrograms from './Components/Programs';
import Container from 'react-bootstrap/Container';

const Home: React.FunctionComponent = () => {
  return (
    <div className="home-container white-background">
      <Hero />
      <Why />
      <Purple />
      <Cards />
      <AboutUs />
      <ScholarshipPrograms />
      <Container>
        <Subscribe
          leftColBreakPoints={[7, 7, 7]}
          rightColBreakPoins={[12, 12, 5]}
        />
      </Container>
      <StartSearch />
    </div>
  );
};

export default Home;
