import './style.scss';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Arrow from '../../assets/icons/arrow.svg';
import omit from 'lodash/omit';
import { Option } from '../../constants';

type FilterProps = {
  options: Array<Option>;
  handleCheck: (
    selectedOptions: Option[],
    lastClickedOption: Option | undefined,
    keyName: string,
  ) => void;
  title?: string;
  keyName: string;
};

type FilterChecker = {
  checked?: boolean;
  text: string;
  dataField: string;
};

const Filter: React.FunctionComponent<FilterProps> = ({
  options: optionsProps,
  handleCheck,
  title = 'Filtro',
  keyName,
}: FilterProps) => {
  const [hide, setHide] = useState<boolean>(false);
  const [options, setOptions] = useState<FilterChecker[]>(
    optionsProps as FilterChecker[],
  );

  const handleCheckboxClick = (clicked: Option, idx: number) => {
    const allOptions = options;
    allOptions[idx].checked = !allOptions[idx].checked;
    setOptions(allOptions);

    // "checked" field removed  as it is reduntant
    const selectedOptions = allOptions
      .filter((op) => op.checked)
      .map((op) => omit(op, 'checked'));

    handleCheck(selectedOptions, omit(clicked, 'checked'), keyName);
  };

  return (
    <div className={`filter-card ${hide ? 'hide' : ''}`}>
      <div className="title" onClick={() => setHide(!hide)}>
        <img src={Arrow} className="dropdown-icon" alt="" />
        <span className="label">{title}</span>
      </div>
      <hr />
      <div className="options">
        {options.map((op, idx) => {
          // This is to ensure the ids hardly collision on pages with this
          // component multiple times
          const randomId = Math.floor(100 + Math.random() * 900).toString();

          return (
            <Form.Check
              defaultChecked={op.checked}
              id={`check-${randomId}`}
              key={idx}
              onClick={() => handleCheckboxClick(op, idx)}
              type="checkbox"
              label={<label htmlFor={`check-${randomId}`}>{op.text}</label>}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Filter;
