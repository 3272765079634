import React from 'react';
import './style.scss';
import Modal from 'react-bootstrap/Modal';
import Title from '../Title';
import Button from '../Button';

type FiltersModalProps = {
  onHide: any;
  show: boolean;
  children: any;
  title?: string;
  className?: string;
  cleanSearch: any;
};

const FiltersModal: React.FunctionComponent<FiltersModalProps> = ({
  onHide,
  show,
  children,
  title = 'Filtrar búsqueda',
  className = '',
  cleanSearch,
}: FiltersModalProps) => {
  return (
    <Modal
      className="filter-modal"
      dialogClassName={`search-modal-dialog filter-modal ${className}`}
      show={show}
      onHide={onHide}
    >
      <button type="button" onClick={onHide} className="close">
        <span>Listo</span>
      </button>
      <Title variant="h2">{title}</Title>
      {children}

      <span onClick={cleanSearch} className="link-alt mt-5">
        Limpiar búsqueda
      </span>
      <div className="cta-wrapper">
        <Button onClick={onHide} block style="pink">
          Listo
        </Button>
      </div>
    </Modal>
  );
};

export default FiltersModal;
