import './style.scss';
import React, { memo } from 'react';
import Container from 'react-bootstrap/Container';
import BaseCard from '../Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

type CompleteProfileProps = {
  className?: string;
};

const CompleteProfile: React.FunctionComponent<CompleteProfileProps> = ({
  className,
}) => {
  return (
    <BaseCard className={`py-3 complete-profile-card ${className} `}>
      <Row noGutters>
        <Col sm="1" className="icon">
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </Col>

        <Col sm="11" className="text">
          <span id="complete-profile-text">
            Completa tu perfil académico y recibe recomendaciones con más
            probabilidades para ti.
            {/*Change url here*/}
          </span>
          <br />
          <Link to="/perfil/editar">Haz clic aquí</Link>
        </Col>
      </Row>
    </BaseCard>
  );
};

export default memo(CompleteProfile);
