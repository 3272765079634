import './style.scss';
import React, { memo } from 'react';

type TitleProps = {
  className?: string;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | undefined;
  color?: 'black' | 'white' | 'grey' | undefined;
  align?: 'left' | 'center' | 'right';
  weight?: 'bold' | 600 | 500 | 400 | 300 | undefined;
  size?: string;
  children: string | JSX.Element | JSX.Element[] | any;
};

const Title: React.FunctionComponent<TitleProps> = ({
  className = '',
  variant = 'h1',
  color = 'black',
  align = 'left',
  weight = 'bold',
  size = undefined,
  children,
}: TitleProps) => {
  const style = {
    color:
      color == 'black' ? '#000000' : color == 'white' ? '#ffffff' : '#707070',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: weight,
    textAlign: align,
    fontSize: size,
  };

  return (
    <>
      {variant === 'h1' && (
        <h1 className={`${className} custom-h1-title`} style={style}>
          {children}
        </h1>
      )}
      {variant === 'h2' && (
        <h2 className={`${className} custom-h2-title`} style={style}>
          {children}
        </h2>
      )}
      {variant === 'h3' && (
        <h3 className={`${className} custom-h3-title`} style={style}>
          {children}
        </h3>
      )}
      {variant === 'h4' && (
        <h4 className={`${className} custom-h4-title`} style={style}>
          {children}
        </h4>
      )}
      {variant === 'h5' && (
        <h5 className={`${className} custom-h5-title`} style={style}>
          {children}
        </h5>
      )}
    </>
  );
};

export default memo(Title);
