import './style.scss';
import React, { memo, useState, useContext, useEffect } from 'react';
import GirlConfused from '../../assets/images/commons/girl-confused.svg';
import Callout from '../Callout';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Title from '../Title';
import Button from '../Button';
import UserContext from '../../UserContext';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routes';

type props = {
  title?: string;
  subtitle?: string;
};

const NoResults: React.FunctionComponent<props> = ({
  title = 'Lo sentimos pero no pudimos encontrar algo que se ajuste a tu búsqueda',
  subtitle = 'No te desanimes; intenta buscando en áreas relacionadas.',
}: props) => {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const user = useContext(UserContext);

  useEffect(() => {
    setLoggedIn(user.ready && user.data !== undefined);
  }, [user]);

  return (
    <div className="mt-5" id="no-results">
      <img src={GirlConfused} alt="Joven no encontrando pagina web" />
      <p>{title}</p>
      <p className="mb-5">{subtitle}</p>

      {!loggedIn && (
        <Callout className="register mb-4">
          <Row>
            <Col lg="8">
              <Title
                className="mb-3"
                size="1em"
                align="left"
                color="white"
                variant="h3"
              >
                Destaca tu perfil académico con una beca internacional.
              </Title>
            </Col>
            <Col lg="4">
              <Link to={ROUTES.signin}>
                <Button block variant="outline-secondary">
                  Regístrate gratis
                </Button>
              </Link>
            </Col>
          </Row>
        </Callout>
      )}
    </div>
  );
};

export default memo(NoResults);
