import React, { useState, useContext, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ScholarshipCard from '../../components/Card/ScholarshipCard';
import { Scholarship } from '../../models/index';
import truncate from 'lodash/truncate';
import UserContext from '../../UserContext';
import Scholarships from '../../Services/Scholarships';
import SearchNoRegisterService from '../../Services/SearchNoRegister';
import { useHistory } from 'react-router-dom';
import { getDateFromString } from '../../Utils';
import { ROUTES } from '../../routes';
import has from 'lodash/has';
import RegisterCallout from '../Callout/RegisterCallout';

type GenerateCardsRowProps = {
  data: Scholarship[];
  start?: number;
  finish?: number;
  setSignupModal?: React.Dispatch<React.SetStateAction<boolean>>;
  lg?: number;
  registerCallout?: boolean;
  savedScholarships?: Scholarship[];
  onAfterSave?: (scholarships: string[]) => void;
};

const GenerateCardsRow: React.FunctionComponent<GenerateCardsRowProps> = ({
  data,
  start = 0,
  finish = data.length,
  setSignupModal,
  lg = 4,
  registerCallout = false,
  savedScholarships,
  onAfterSave,
}: GenerateCardsRowProps) => {
  const slicedData = data.slice(start, finish);
  const [saved, setSaved] = useState<Array<string>>(Array<string>());
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  const history = useHistory();

  const user = useContext(UserContext);

  useEffect(() => {
    setLoggedIn(user.ready && user.data !== undefined);
  }, [user]);

  useEffect(() => {
    if (user.ready && user.data !== undefined) {
      const getSavedScholarships = async () => {
        const id = user.data?.id_usuario!;
        const savedArr = await Scholarships.getSavedScholarships(id);
        setSaved([...savedArr]);
      };
      getSavedScholarships();
    }
  }, [user, savedScholarships]);

  const handleDetail = (id: string) => {
    if (has(user.data, 'id_usuario') || SearchNoRegisterService.verifyId(id)) {
      history.push(ROUTES.detail.replace(':id', id));
      return;
    }

    if (SearchNoRegisterService.hasSearchLeft()) {
      const searchRegister = SearchNoRegisterService.getSearchNoRegister();
      const firstSearch = getDateFromString(searchRegister.firstSearch);
      const today = getDateFromString(new Date());

      searchRegister.searches += 1;
      searchRegister.scholarships.push(id);

      if (firstSearch.day == today.day && firstSearch.month == today.month) {
        searchRegister.firstSearch = new Date();
      }

      SearchNoRegisterService.saveSearch(searchRegister);
      history.push(ROUTES.detail.replace(':id', id));
    } else {
      setSignupModal!(true);
    }
  };

  return (
    <Row>
      {slicedData.map((el, idx: number) => (
        <>
          {el !== undefined && (
            <React.Fragment key={el.id_beca}>
              <Col className="mb-4" sm="12" md="4" lg={lg}>
                <ScholarshipCard
                  data={{
                    id: el.id_beca,
                    university: truncate(el.organizacion, { length: 33 }),
                    country:
                      el.pais && el.pais.length > 0
                        ? el.pais.join(' | ')
                        : 'No definido',
                    days_left:
                      el.fecha_limite && el.fecha_limite.length > 0
                        ? el.fecha_limite
                        : 'No definido',
                    text: truncate(el.nombre, { length: 63 }),
                  }}
                  saved={saved.indexOf(el.id_beca!) > -1}
                  onClick={handleDetail}
                  onAfterSave={onAfterSave}
                />
              </Col>
              {/* If user is not logged in, show sign up modal at the end of scholarships,
          or in the 3rd row is the amount is bigger than 6 */}
              {registerCallout &&
                !loggedIn &&
                ((slicedData.length < 6 && idx === slicedData.length - 1) ||
                  idx === 5) && (
                  <Col lg="12">
                    <RegisterCallout />
                  </Col>
                )}
            </React.Fragment>
          )}
        </>
      ))}
    </Row>
  );
};

export default GenerateCardsRow;
