import './style.scss';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Title from '../../components/Title';
import GuySitting from '../../assets/images/views/notFound/guy-sitting.svg';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RectangleYellow from '../../assets/icons/rectangle-yellow.svg';
import RectangleBlue from '../../assets/icons/rectangle-blue.svg';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routes';

const NotFound: React.FunctionComponent = () => {
  return (
    <div id="generic">
      <img src={RectangleYellow} className="overlay-rectangle-yellow" alt="" />
      <img src={RectangleBlue} className="overlay-rectangle-blue" alt="" />
      <Container>
        <Row>
          <Col className="info" lg="6" md="12">
            <Title className="mb-5" variant="h1">
              ¡Upps! Parece que esta página no existe y no encontramos lo que
              andas buscando.
            </Title>

            <Link to={ROUTES.home} className="go-back">
              Volver a inicio
              <FontAwesomeIcon className="icon" icon={faArrowRight} />
            </Link>
          </Col>
          <Col className="guy-sitting" lg="6" md="12">
            <img src={GuySitting} alt="guy sitting" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NotFound;
