import './style.scss';
import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Arrow from '../../../../assets/icons/arrow.svg';
import BaseButton from '../../../../components/Button';
import Dropdown from '../../../../components/Dropdown';
import { handleSearchRedirect } from '../../../Scholarships/utils';
import ScholarshipService from '../../../../Services/Scholarships';
import { ScholarshipFilters } from '../../../../models';
import { useHistory } from 'react-router-dom';
import {
  Option,
  FIRST_FILTER,
  SECOND_FILTER,
  LG_BREAKPOINT,
} from '../../../../constants';
import useWindowWidth from '../../../../hooks/useWindowWidth';
import Container from 'react-bootstrap/Container';
import Search from '../../../../assets/icons/search.svg';
import SearchBar from '../../../../components/SearchBar/index';

const Hero: React.FunctionComponent = () => {
  const [selectedItem, setSelectedItem] = useState<string>('Todas las áreas');
  const [isTablet, setIsTablet] = useState<boolean>(false);
  const [secondSelectedItem, setSecondSelectedItem] = useState<string>(
    'Todos los países',
  );
  const [formFields, setFormFields] = useState<ScholarshipFilters>({
    area_estudio: [],
    idiomas: [],
    nivel_estudios: [],
    pais: [],
    duracion: [],
  });

  const width = useWindowWidth();

  useEffect(() => {
    setIsTablet(width <= LG_BREAKPOINT);
  }, [width]);

  const getFormFields = async () => {
    const fields = await ScholarshipService.getFilterInputs();
    setFormFields(fields);
  };

  useEffect(() => {
    getFormFields();
  }, []);

  useEffect(() => {}, [formFields]);

  const handleSearch = () => {
    const redirect = handleSearchRedirect(history);
    const isDefault = selectedItem == 'Todas las áreas' ? true : false;
    const isDefaultTwo =
      secondSelectedItem == 'Todos los países' ? true : false;

    const firstOption: Option = {
      dataField: isDefault ? undefined : selectedItem,
      text: selectedItem,
    };

    const secondOption: Option = {
      dataField: isDefaultTwo ? undefined : secondSelectedItem,
      text: secondSelectedItem,
    };

    redirect(firstOption, secondOption, FIRST_FILTER, SECOND_FILTER);
  };

  const history = useHistory();

  const doHandleSearchRedirect = handleSearchRedirect(history);

  return (
    <header id="hero-home">
      <Container>
        <Row>
          <Col xl={8}>
            <div>
              <h1 className="hero-title">
                Descubre todas las oportunidades de becas disponibles para ti
              </h1>
              <p>
                Estudia en el país de tus sueños, viaja por el mundo,{' '}
                {!isTablet && <br />}
                disfruta tu vida al máximo.
              </p>
            </div>
          </Col>
          {!isTablet ? (
            <div id="hero-inputs">
              <img src={Search} alt="icono de busqueda de becas" />
              <Dropdown
                placeholder="Qué deseas estudiar"
                // prependIcon={faSearch}
                appendIcon={Arrow}
                ariaLabel="Tipo de beca"
                setSelectedValue={setSelectedItem}
                data={formFields.area_estudio}
              />

              <Dropdown
                placeholder="Dónde deseas estudiar"
                ariaLabel="Lugar de estudio"
                inputClassName="input-padding-left"
                setSelectedValue={setSecondSelectedItem}
                data={formFields.pais}
                appendIcon={Arrow}
              />

              <div className="hero-button-container">
                <BaseButton style="pink" onClick={handleSearch}>
                  Buscar
                </BaseButton>
              </div>
            </div>
          ) : (
            <Col lg="12" className="search-bar-mobile">
              <SearchBar
                handleSearch={doHandleSearchRedirect}
                cleanSearch={false}
              />
            </Col>
          )}
        </Row>
      </Container>
    </header>
  );
};

export default Hero;
