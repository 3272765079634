import { SearchNoRegister, DAYS_LIMIT, SEARCH_LIMIT } from '../../constants';
import { getDateFromString } from '../../Utils/index';

class SearchNoRegisterService {
  SEARCH_NO_REGISTER = 'search_no_register';

  saveSearch(data: SearchNoRegister): void {
    localStorage.setItem(this.SEARCH_NO_REGISTER, JSON.stringify(data));
  }

  getSearchNoRegister(): SearchNoRegister {
    const emptySearch: SearchNoRegister = {
      firstSearch: undefined,
      scholarships: [],
      searches: 0,
    };

    return localStorage.getItem(this.SEARCH_NO_REGISTER)
      ? JSON.parse(localStorage.getItem(this.SEARCH_NO_REGISTER)!)
      : emptySearch;
  }

  verifyId(scholarshipId: string): string | undefined {
    const ids: SearchNoRegister = localStorage.getItem(this.SEARCH_NO_REGISTER)
      ? JSON.parse(localStorage.getItem(this.SEARCH_NO_REGISTER)!)
      : [];

    return ids.scholarships ? ids.scholarships.find((id) => id === scholarshipId) : undefined;
  }

  hasSearchLeft(): boolean {
    const data = this.getSearchNoRegister();
    const todaysDate = getDateFromString(new Date());
    const firstSearchDate = getDateFromString(data.firstSearch);
    let isValid = false;

    const daysDifference = Math.abs(
      Number(todaysDate.day) - Number(firstSearchDate.day),
    );

    if (daysDifference > DAYS_LIMIT) {
      isValid = true;
      this.reset();
    } else {
      if(data.searches < SEARCH_LIMIT) {
        isValid = true;
      } else {
        isValid = false;
      }
    }

    return isValid;
  }

  reset(): void {
    const temp: SearchNoRegister = {
      firstSearch: undefined,
      scholarships: [],
      searches: 0,
    };

    localStorage.setItem(this.SEARCH_NO_REGISTER, JSON.stringify(temp));
  }
}

export default new SearchNoRegisterService();
