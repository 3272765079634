import './style.scss';
import React, { memo } from 'react';
import Card from 'react-bootstrap/Card';

type BaseCardProps = {
  height?: string | number;
  width?: string | number;
  className?: string;
  children: any;
};

const BaseCard: React.FunctionComponent<BaseCardProps> = ({
  height,
  width,
  className = '',
  children,
}: BaseCardProps) => (
  <Card className={`base-card ${className}`} style={{ height, width }}>
    {children}
  </Card>
);

export default memo(BaseCard);
