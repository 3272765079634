import './style.scss';
import React, { memo, useState, useEffect, useContext } from 'react';
import Students from '../../../assets/images/views/home/mailing-home.jpg';
import ComboBox from '../index';
import Title from '../../Title';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import BaseButton from '../../Button';
import WhiteLetter from '../../../assets/icons/white-letter.svg';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { VALIDATION_MESSAGES } from '../../../constants';
import Scholarships from '../../../Services/Scholarships';
import UserContext from '../../../UserContext';

type SubscribeProps = {
  backgroundColor?: 'pink' | 'purple';
  onClick?: () => void;
  leftColBreakPoints?: number[];
  rightColBreakPoins?: number[];
  gutters?: boolean;
  isHome?: boolean;
};

const Subscribe: React.FunctionComponent<SubscribeProps> = ({
  backgroundColor = 'pink',
  onClick,
  leftColBreakPoints = [12, 8, 8],
  rightColBreakPoins = [12, 4, 4],
  gutters = true,
  isHome = true,
}: SubscribeProps) => {
  const [subscribed, setSubscribed] = useState<boolean>(false);
  const [alreadySubscribed, setAlreadySubscribed] = useState<boolean>(false);

  const user = useContext(UserContext);

  useEffect(() => {
    if (user.ready && user.data !== undefined) {
      const email = user.data?.correo_elect! ? user.data?.correo_elect! : '';
      const checkSubscribed = async () => {
        const subbed = await Scholarships.checkIfSubscribedToNewsLetter(email);
        setAlreadySubscribed(subbed);
      };
      checkSubscribed();
    }
  }, [user]);

  const bgColor = backgroundColor == 'pink' ? '#ee4860' : '#384797';

  const form = { email: '' };

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(VALIDATION_MESSAGES.EMAIL)
      .required(VALIDATION_MESSAGES.REQUIRED),
  });

  const {
    handleSubmit,
    setFieldError,
    setSubmitting,
    handleChange,
    values,
    errors,
  } = useFormik({
    initialValues: form,
    validationSchema: schema,
    onSubmit: async ({ email }) => {
      const subscribed = await Scholarships.subscribeEmailToNewsLetter(email);

      if (subscribed) {
        setSubscribed(true);
      } else {
        setFieldError(
          'email',
          'Ha ocurrido un error al suscribirse. Intente mas tarde',
        );
        setSubmitting(false);
        return;
      }
    },
  });

  return (
    <div className={alreadySubscribed ? 'd-none' : ''}>
      <ComboBox
        img={Students}
        imagePosition="left"
        fluid={false}
        rowClassName="students-row my-5"
        rightColClassname={
          isHome ? 'students-right-col' : 'alternative-students-right-col'
        }
        leftColClassname={`students-left-col ${
          isHome ? '' : 'alternative-students-left-col'
        }`}
        leftColBreakpoints={leftColBreakPoints}
        rightColBreakPoints={rightColBreakPoins}
        backgroundColor={bgColor}
        alt="estudiantes universitarios"
        gutters={gutters}
      >
        {!subscribed ? (
          <>
            <h2>¿Te gustaría recibir el TOP 10 de becas internacionales?</h2>
            <p>
              Suscríbete a nuestro newsletter y mantente actualizado sobre todas
              las novedades y programas disponibles para estudiantes
              internacionales
            </p>
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <InputGroup className="my-4">
                  <FormControl
                    name="email"
                    placeholder="Correo eléctronico"
                    onChange={handleChange}
                    value={values.email}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <BaseButton
                style="transparent"
                variant="outline-secondary"
                width="100%"
                onClick={onClick}
                type="submit"
              >
                Si, quiero suscribirme
              </BaseButton>
            </Form>
          </>
        ) : (
          <div
            className="d-flex flex-column justify-content-center"
            style={{ height: '100%' }}
          >
            <Title className="mb-4" variant="h3" color="white" align="left">
              ¡Ya estas registrado en nuestro newsletter!
            </Title>
            <p>
              Te mantendremos actualizado sobre todas las novedades y programas
              disponibles para estudiantes internacionales.
            </p>
          </div>
        )}
      </ComboBox>
    </div>
  );
};

export default Subscribe;
