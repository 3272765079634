import './style.scss';
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import BaseButton from '../Button';
import { Link, NavLink } from 'react-router-dom';
import { ROUTES } from '../../routes';
import useWindowWidth from '../../hooks/useWindowWidth';
import { SM_BREAKPOINT } from '../../constants';
import SignUpModal from '../Modal/SignUpModal';

type TabsProps = {
  text?: string[];
  active?: number;
  className?: string;
  urls?: string[];
  fixed?: boolean;
  isLoggedin?: boolean;
};

const BaseTabs: React.FunctionComponent<TabsProps> = ({
  text = ['Inicio', 'Recomendaciones', 'Becas guardadas', 'Buscar'],
  active = -1,
  className = '',
  fixed = false,
  urls = [
    ROUTES.scholarships,
    ROUTES.scholarshipsRecommended,
    ROUTES.scholarshipsSaved,
    ROUTES.search,
  ],
  isLoggedin = true,
}: TabsProps) => {
  const width = useWindowWidth();
  const [show, setShow] = useState<boolean>(false);

  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    setIsMobile(width <= SM_BREAKPOINT);
  }, [width]);

  const handleShow = () => setShow((show) => !show);
  return (
    <div id="tabs-component-wrapper" className={className}>
      <SignUpModal show={show} onHide={handleShow} />
      <Container
        fluid
        className={`${fixed ? 'fixed' : ''} ${isMobile ? 'px-0' : ''}`}
      >
        <Container className={isMobile ? 'px-0' : ''}>
          <div id="tab-elements">
            {text.map((el, idx) => (
              <NavLink
                key={urls[idx]}
                to={!isLoggedin && idx !== 3 ? urls[3] : urls[idx]}
                onClick={!isLoggedin && idx !== 3 ? handleShow : undefined}
              >
                <BaseButton
                  className={idx == active ? 'active-tab' : ''}
                  style="transparent"
                  key={el}
                >
                  {el}
                </BaseButton>
              </NavLink>
            ))}
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default BaseTabs;
