import './style.scss';
import React, { memo } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BaseButton from '../../../../components/Button';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { ROUTES } from '../../../../routes';

const StartSearch: React.FunctionComponent = () => {
  return (
    <section id="sec4">
      <Container>
        <Row
          className="start-search-row"
        >
          <Col xl={6}>
            <h2 className="study-abroad-title">
              Estudiar en el extranjero con una beca nunca ha sido más fácil.
            </h2>

            <h4>
              Aprovecha todas las oportunidades disponibles para tu perfil en un
              solo lugar y de forma gratuita.
            </h4>

            <Link to={ROUTES.search}>
              <BaseButton style="white" >
                Iniciar mi búsqueda
              </BaseButton>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default memo(StartSearch);
