import React from 'react';
import { Option, FILTERS_STR } from '../../constants';
import { ScholarshipFilters } from '../../models';
import isEmpty from 'lodash/isEmpty';
import { ROUTES } from '../../routes';
import Filter from '../../components/Filter/index';

export const options = [
  { text: 'Ciencias de la computacion', dataField: 'computacion' },
  { text: 'Ingenieria Civil', dataField: 'civil' },
  { text: 'Medicina', dataField: 'medicina' },
  { text: 'Ingenieria en Software', dataField: 'software' },
  { text: 'Leyes', dataField: 'leyes' },
  { text: 'Comunicacion audiovisual', dataField: 'cine' },
];

export const iterTimes = (num: number) => Array(num).fill('', 0, num);

export const handleSearchRedirect = (history: any) => (
  first: Option | undefined,
  second: Option | undefined,
  firstKeyName: string | undefined,
  secondKeyName: string | undefined,
) => {
  let newFilter: ScholarshipFilters = {
    [firstKeyName!]: [],
    [secondKeyName!]: [],
  };

  if (first !== undefined) {
    newFilter[firstKeyName!] = [first.dataField];
  }
  if (second !== undefined) {
    newFilter[secondKeyName!] = [second.dataField];
  }

  if (!isEmpty(newFilter)) {
    history.push(ROUTES.search, newFilter);
  }
};

export const map_filters = (handleCheck: any) => (option: any, idx: number) => {
  return (
    <React.Fragment key={idx}>
      <Filter
        options={(option[1] as unknown) as Option[]}
        handleCheck={handleCheck}
        title={FILTERS_STR[option[0]]}
        keyName={option[0]}
      />
      <br />
    </React.Fragment>
  );
};
