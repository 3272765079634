import './style.scss';
import React, { memo } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Search from '../../../../assets/images/views/home/search2.svg';
import Choose from '../../../../assets/images/views/home/elegir-becas.svg';
import Learn from '../../../../assets/images/views/home/learn.svg';

const Purple: React.FunctionComponent = () => {
  return (
    <section id="get">
      <Container>
        <Row className="purple-background-row">
          <Col xl={6} lg={6} md={12} sm={12}>
            <h2 className="study-abroad-title">
              Con <span>infobeca</span>, puedes obtener toda la información
              necesaria para encontrar la beca ideal según tus intereses.
            </h2>
          </Col>

          <Col xl={6} lg={6} md={12} sm={12}>
            <div className="get-2">
              <img src={Search} alt="Encontrar programa de beca" />
              <p>Encontrar todos los programas de becas en un solo lugar</p>
            </div>

            <div className="get-2">
              <img src={Choose} alt="Elegir programas de becas" />
              <p>
                Elegir los programas que mejor se adaptan a tu perfil e
                intereses académicos
              </p>
            </div>

            <div className="get-2">
              <img src={Learn} alt="Aprender sobre becas" />
              <p>
                Aprender todos los pasos para obtener una beca de forma exitosa
                desde el principio
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default memo(Purple);
