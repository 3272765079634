import React, { useState, useContext, useEffect } from 'react';
import {
  Option,
  OMIT_FILTERS,
  PAGINATION_SIZE,
  LG_BREAKPOINT,
} from '../../../constants';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SearchBar from '../../../components/SearchBar/index';
import Button from '../../../components/Button';
import Title from '../../../components/Title';
import NotSavedIcon from '../../../assets/icons/not-saved.svg';
import './style.scss';
import BaseTabs from '../../../components/Tabs/index';
import { FunctionComponentPage } from '../../function-component-page';
import {
  ScholarshipFiltersProps,
  ScholarshipFilters,
  Scholarship,
} from '../../../models';
import { Request, Response } from 'express';
import { match as Match } from 'react-router-dom';
import { filterFilters } from '../../../Utils';
import omit from 'lodash/omit';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import UserContext from '../../../UserContext';
import Scholarships from '../../../Services/Scholarships';
import Users from '../../../Services/Users';
import { handleSearchRedirect, map_filters } from '../../Scholarships/utils';
import CompleteProfile from '../../../components/CompleteProfile';
import PuffLoader from 'react-spinners/PuffLoader';
import ClipLoader from 'react-spinners/ClipLoader';
import NoResults from '../../../components/NoResults/NoResults';
import SignUpModal from '../../../components/Modal/SignUpModal';
import GenerateCardsRow from '../../../components/GenerateCardRow/index';
import FiltersModal from '../../../components/FiltersModal/index';
import FilterIcon from '../../../assets/icons/filter.svg';
import { useWindowWidth } from '@react-hook/window-size';
import { scrollToTop } from '../../../Utils/index';
import UpIcon from '../../../assets/icons/up.svg';

const SavedScholarhips: FunctionComponentPage<ScholarshipFiltersProps> = ({
  data: filters,
}: ScholarshipFiltersProps) => {
  const [loginModal, setLoginModal] = useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [completedProfile, setCompletedProfile] = useState<boolean>(false);
  const [saved, setSaved] = useState<Scholarship[]>([]);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [quantity, setQuantity] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [filter, setFilter] = useState<ScholarshipFilters>({
    nivel_estudios: [],
    area_estudio: [],
    duracion: [],
    pais: [],
    idiomas: [],
  });

  const [loadingNext, setLoadingNext] = useState<boolean>(false);
  const [loadingAllSc, setLoadingAllSc] = useState<boolean>(false);

  const options = filterFilters(omit(filters, OMIT_FILTERS));
  const [optionsKeys] = useState(Object.entries(options));

  const history = useHistory();
  const user = useContext(UserContext);

  useEffect(() => {
    if (user.ready) {
      if (user.data !== undefined) {
        setLoggedIn(true);
      } else {
        setLoginModal(true);
      }
    }
  }, [user]);

  const loadSaved = async (user_id: string) => {
    setLoadingAllSc(true);
    const savedSc = await Scholarships.filterScholarshipCustom(
      user_id,
      1,
      PAGINATION_SIZE,
      filter,
      'saved',
    );

    const scholarships = savedSc.error ? [] : (savedSc.data as Scholarship[]);
    setQuantity(savedSc.error ? 0 : savedSc.quantity!);
    setHasMore(scholarships.length >= PAGINATION_SIZE);
    setSaved(scholarships);
    setLoadingAllSc(false);
  };

  const loadNextPage = async () => {
    setSaved([]);
    setLoadingNext(true);
    const nextPage = page + 1;
    const sc = await Scholarships.filterScholarshipCustom(
      user.data!.id_usuario!,
      nextPage,
      PAGINATION_SIZE,
      filter,
      'saved',
    );
    const scCast = sc.data as Scholarship[];
    setSaved([...saved, ...scCast]);
    setPage(page + 1);
    setHasMore(scCast.length >= PAGINATION_SIZE);
    setLoadingNext(false);
  };

  const handleCheck = (
    selectedOptions: Option[],
    _: Option | undefined,
    keyName: string,
  ) => {
    const newFilter = {
      ...filter,
      [keyName!]: selectedOptions.map((op) => op.dataField),
    };

    setFilter(newFilter);
  };

  useEffect(() => {
    if (loggedIn) {
      const user_id = user.data!.id_usuario;

      const checkProfileComplete = async () => {
        setCompletedProfile(await Users.hasCompletedRegisterForm());
      };

      loadSaved(user_id);
      checkProfileComplete();
    }
  }, [loggedIn]);

  useEffect(() => {
    if (loggedIn) {
      const user_id = user.data!.id_usuario;
      loadSaved(user_id);
    }
  }, [filter]);

  const doHandleSearchRedirect = handleSearchRedirect(history);

  const handleModal = () => setShowModal(!showModal);

  const width = useWindowWidth();

  const [isTablet, setIsTablet] = useState<boolean>(false);

  useEffect(() => {
    setIsTablet(width <= LG_BREAKPOINT);
  }, [width]);

  return (
    <div id="saved">
      <SignUpModal
        show={loginModal}
        onHide={() => {
          history.push(ROUTES.search);
        }}
      />
      <FiltersModal
        cleanSearch={handleCheck}
        show={showModal}
        onHide={handleModal}
      >
        {optionsKeys.map(map_filters(handleCheck))}
      </FiltersModal>
      <BaseTabs fixed className="mb-4" active={2} />

      <Container className="mb-4">
        <Row className="filter-page-margin">
          <Col>
            <SearchBar handleSearch={doHandleSearchRedirect} />
            {page > 1 && (
              <img
                src={UpIcon}
                onClick={() => {
                  scrollToTop();
                }}
                className="goToTop"
              />
            )}

            {loggedIn && !completedProfile && (
              <CompleteProfile className="mt-2 mb-4" />
            )}
          </Col>
        </Row>
        <Row>
          {!isTablet && (
            <Col lg="3">{optionsKeys.map(map_filters(handleCheck))}</Col>
          )}

          <Col lg="9">
            <div className="title">
              <img src={NotSavedIcon} alt="icono de guardar" />
              <Title variant="h1">Becas guardadas</Title>
            </div>
            <div className="d-flex justify-content-between">
              <span className="results">{quantity} becas guardadas</span>
              {isTablet && (
                <span onClick={handleModal} className="results clickeable">
                  Filtrar <img src={FilterIcon} alt="icono de filtrar" />{' '}
                </span>
              )}
            </div>
            <hr />

            {loadingAllSc && (
              <div className="puff-loader">
                <PuffLoader size={150} loading={loadingAllSc} />
              </div>
            )}
            {quantity <= 0 && (
              <NoResults title="Aún no tienes becas guardadas." subtitle="" />
            )}

            <div className={quantity <= 0 ? 'd-none' : ''}>
              <GenerateCardsRow data={saved} />

              {hasMore && (
                <Col sm="12" className="pr-0 pl-0">
                  <Button
                    className={loadingNext ? 'loading-btn' : ''}
                    disabled={loadingNext}
                    onClick={loadNextPage}
                    block
                    style="pink"
                  >
                    {loadingNext ? (
                      <div className="clip-loader">
                        <ClipLoader size={28} loading={loadingNext} />
                      </div>
                    ) : (
                      'Ver más'
                    )}
                  </Button>
                </Col>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const getInitialProps = async ({
  req,
  res,
  match,
}: {
  req: Request;
  match: Match<any>;
  res: Response;
}): Promise<ScholarshipFiltersProps> => {
  const filters = await Scholarships.getFilterInputs();

  return { data: filters };
};

SavedScholarhips.getInitialProps = getInitialProps;

export default SavedScholarhips;
