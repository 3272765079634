import React, { useState, useEffect, useContext } from 'react';
import BaseTabs from '../../../components/Tabs';
import SearchBar from '../../../components/SearchBar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  Option,
  PAGINATION_SIZE,
  OMIT_FILTERS,
  LG_BREAKPOINT,
} from '../../../constants';
import { handleSearchRedirect, map_filters } from '../utils';
import CompleteProfile from '../../../components/CompleteProfile';
import Title from '../../../components/Title';
import Button from '../../../components/Button';
import { ScholarshipFilters } from '../../../models';
import { FunctionComponentPage } from '../../function-component-page';
import { Request, Response } from 'express';
import { match as Match } from 'react-router-dom';
import Recommendations from '../../../assets/icons/recomendaciones.svg';
import { useHistory } from 'react-router-dom';
import UserContext from '../../../UserContext';
import { Scholarship } from '../../../models';
import Users from '../../../Services/Users';
import Scholarships from '../../../Services/Scholarships';
import PuffLoader from 'react-spinners/PuffLoader';
import ClipLoader from 'react-spinners/ClipLoader';
import { ScholarshipFiltersProps } from '../../../models/scholarships';
import omit from 'lodash/omit';
import { filterFilters } from '../../../Utils';
import NoResults from '../../../components/NoResults/NoResults';
import SignUpModal from '../../../components/Modal/SignUpModal';
import { ROUTES } from '../../../routes';
import GenerateCardsRow from '../../../components/GenerateCardRow/index';
import FiltersModal from '../../../components/FiltersModal/index';
import FilterIcon from '../../../assets/icons/filter.svg';
import { useWindowWidth } from '@react-hook/window-size';
import { scrollToTop } from '../../../Utils/index';
import UpIcon from '../../../assets/icons/up.svg';

const ScholarshipsRecommended: FunctionComponentPage<ScholarshipFiltersProps> = ({
  data: filters,
}: ScholarshipFiltersProps) => {
  const [loginModal, setLoginModal] = useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [completedProfile, setCompletedProfile] = useState<boolean>(false);
  const [recommended, setRecommended] = useState<Scholarship[]>([]);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [quantity, setQuantity] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [filter, setFilter] = useState<ScholarshipFilters>({
    nivel_estudios: [],
    area_estudio: [],
    duracion: [],
    pais: [],
    idiomas: [],
  });

  const [loadingNext, setLoadingNext] = useState<boolean>(false);
  const [loadingAllSc, setLoadingAllSc] = useState<boolean>(false);

  const options = filterFilters(omit(filters, OMIT_FILTERS));
  const [optionsKeys] = useState(Object.entries(options));

  const history = useHistory();
  const user = useContext(UserContext);

  useEffect(() => {
    if (user.ready) {
      if (user.data !== undefined) {
        setLoggedIn(true);
      } else {
        setLoginModal(true);
      }
    }
  }, [user]);

  const loadRecommended = async (user_id: string) => {
    setRecommended([]);
    setLoadingAllSc(true);
    const recommendedSc = await Scholarships.filterScholarshipCustom(
      user_id,
      1,
      PAGINATION_SIZE,
      filter,
      'recommended',
    );
    const scholarships = recommendedSc.error
      ? []
      : (recommendedSc.data as Scholarship[]);
    setQuantity(recommendedSc.error ? 0 : recommendedSc.quantity!);
    setHasMore(scholarships.length >= PAGINATION_SIZE);
    setRecommended(scholarships);
    setLoadingAllSc(false);
  };

  const loadNextPage = async () => {
    setLoadingNext(true);
    const nextPage = page + 1;
    const sc = await Scholarships.filterScholarshipCustom(
      user.data!.id_usuario!,
      nextPage,
      PAGINATION_SIZE,
      filter,
      'recommended',
    );
    const scCast = sc.data as Scholarship[];
    setRecommended([...recommended, ...scCast]);
    setPage(page + 1);
    setHasMore(scCast.length >= PAGINATION_SIZE);
    setLoadingNext(false);
  };

  const handleCheck = (
    selectedOptions: Option[],
    _: Option | undefined,
    keyName: string,
  ) => {
    const newFilter = {
      ...filter,
      [keyName!]: selectedOptions.map((op) => op.dataField),
    };

    setFilter(newFilter);
  };

  useEffect(() => {
    if (loggedIn) {
      const user_id = user.data!.id_usuario;

      const checkProfileComplete = async () =>
        setCompletedProfile(await Users.hasCompletedRegisterForm());

      loadRecommended(user_id);
      checkProfileComplete();
    }
  }, [loggedIn]);

  useEffect(() => {
    if (loggedIn) {
      const user_id = user.data!.id_usuario;
      loadRecommended(user_id);
    }
  }, [filter]);

  const doHandleSearchRedirect = handleSearchRedirect(history);

  const handleModal = () => setShowModal(!showModal);

  const width = useWindowWidth();

  const [isTablet, setIsTablet] = useState<boolean>(false);

  useEffect(() => {
    setIsTablet(width <= LG_BREAKPOINT);
  }, [width]);

  return (
    <div id="scholarships-recommended-container">
      <SignUpModal
        show={loginModal}
        onHide={() => {
          history.push(ROUTES.search);
        }}
      />
      <FiltersModal
        cleanSearch={handleCheck}
        show={showModal}
        onHide={handleModal}
      >
        {optionsKeys.map(map_filters(handleCheck))}
      </FiltersModal>
      <BaseTabs fixed className="mb-4" active={1} />

      <Container className="mb-4 filter-page-margin">
        <SearchBar handleSearch={doHandleSearchRedirect} />

        {page > 1 && (
          <img
            src={UpIcon}
            onClick={() => {
              scrollToTop();
            }}
            className="goToTop"
          />
        )}

        {loggedIn && !completedProfile && (
          <CompleteProfile className="mt-2 mb-4" />
        )}

        <div id="scholarships-content-container">
          <Row>
            {!isTablet && (
              <Col lg="3">{optionsKeys.map(map_filters(handleCheck))}</Col>
            )}

            <Col sm="12" md="12" lg="9">
              <Row className="mb-4">
                <Col sm="12" md="6">
                  <Title variant="h3">
                    <img
                      src={Recommendations}
                      className="title-icon mb-2"
                      alt="becas recomendadas"
                    />
                    Recomendaciones para ti
                  </Title>
                  <div className="d-flex justify-content-between">
                    <span className="results">
                      {quantity} resultados encontrados
                    </span>
                    {isTablet && (
                      <span
                        onClick={handleModal}
                        className="results clickeable"
                      >
                        Filtrar <img src={FilterIcon} alt="icono de filtrar" />{' '}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
              {quantity <= 0 && <NoResults />}

              {loadingAllSc && (
                <div className="puff-loader">
                  <PuffLoader size={150} loading={loadingAllSc} />
                </div>
              )}

              <div className={quantity <= 0 ? 'd-none' : ''}>
                <GenerateCardsRow data={recommended} />
                {hasMore && (
                  <Row>
                    <Col sm="12" className="pr-0 pl-0">
                      <Button
                        className={loadingNext ? 'loading-btn' : ''}
                        disabled={loadingNext}
                        onClick={loadNextPage}
                        block
                        style="pink"
                      >
                        {loadingNext ? (
                          <div className="clip-loader">
                            <ClipLoader size={28} loading={loadingNext} />
                          </div>
                        ) : (
                          'Ver más'
                        )}
                      </Button>
                    </Col>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

const getInitialProps = async ({
  req,
  res,
  match,
}: {
  req: Request;
  match: Match<any>;
  res: Response;
}): Promise<ScholarshipFiltersProps> => {
  const filters = await Scholarships.getFilterInputs();

  return { data: filters };
};

ScholarshipsRecommended.getInitialProps = getInitialProps;

export default ScholarshipsRecommended;
