import './style.scss';
import React, { useState, useEffect } from 'react';
import Card from '../Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '../Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { Option, LG_BREAKPOINT } from '../../constants';
import { LabelToggle } from '../../Utils/index';
import { useWindowWidth } from '@react-hook/window-size';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import Title from '../Title';
import Scholarships from '../../Services/Scholarships';

type SearchBarProps = {
  firstOptionText?: string;
  firstOptionDefault?: string;
  firstOptions?: Option[];
  secondOptionsText?: string;
  secondOptionDefault?: string;
  secondOptions?: Option[];
  defaultFirstSelected?: Option;
  defaultSecondSelected?: Option;
  handleSearch: (
    firstOption: Option | undefined,
    secondOption: Option | undefined,
    firstKeyName: string | undefined,
    secondKeyName: string | undefined,
  ) => void;
  cleanSearch?: boolean;
  firstKeyName?: string;
  secondKeyName?: string;
  showBtn?: boolean;
};

const SearchBar: React.FunctionComponent<SearchBarProps> = ({
  firstOptionText = 'Área de estudio',
  secondOptionsText = 'Dónde deseas estudiar',
  firstOptionDefault = 'Todas las áreas',
  secondOptionDefault = 'Todos los países',
  firstOptions = [],
  secondOptions = [],
  cleanSearch = true,
  handleSearch,
  firstKeyName = 'area_estudios',
  secondKeyName = 'pais',
  showBtn = true,
  defaultFirstSelected,
  defaultSecondSelected,
}: SearchBarProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isTablet, setIsTablet] = useState<boolean>(false);
  const [selectedFirstOption, setSelectedFirstOption] = useState<
    Option | undefined
  >(defaultFirstSelected);
  const [selectedSecondOption, setSelectedSecondOption] = useState<
    Option | undefined
  >(defaultSecondSelected);
  const [fOptions, setFOptions] = useState<Option[]>([
    { dataField: undefined, text: firstOptionDefault },
  ]);
  const [sOptions, setSOptions] = useState<Option[]>([
    { dataField: undefined, text: secondOptionDefault },
  ]);
  const handleModal = () => {
    setShowModal(!showModal);
  };

  const width = useWindowWidth();

  // @ts-ignore
  const firstToggle = LabelToggle('', 'searchbar', firstOptionText);
  const secondToggle = LabelToggle('', 'searchbar', secondOptionsText);

  const btnToggle = LabelToggle('search-btn', 'button');

  useEffect(() => {
    setIsTablet(width <= LG_BREAKPOINT);
  }, [width]);

  useEffect(() => {
    if (defaultFirstSelected) {
      setSelectedFirstOption(defaultFirstSelected);
    }
  }, [defaultFirstSelected]);

  useEffect(() => {
    if (defaultSecondSelected) {
      setSelectedSecondOption(defaultSecondSelected);
    }
  }, [defaultSecondSelected]);

  useEffect(() => {
    if (firstOptions.length === 0 || secondOptions.length == 0) {
      const setOptions = async () => {
        const { area_estudio, pais } = await Scholarships.getFilterInputs();

        setFOptions([
          ...fOptions,
          ...area_estudio!.map((op: any) => {
            return {
              text: op,
              dataField: op,
            };
          }),
        ]);
        setSOptions([
          ...sOptions,
          ...pais!.map((op: any) => {
            return {
              text: op,
              dataField: op,
            };
          }),
        ]);
      };
      setOptions();
    } else {
      setFOptions(firstOptions);
      setSOptions(secondOptions);
    }
  }, []);

  const doHandleSearch = () =>
    handleSearch(
      selectedFirstOption,
      selectedSecondOption,
      firstKeyName,
      secondKeyName,
    );

  useEffect(() => {
    if (!showBtn) {
      doHandleSearch();
    }
  }, [selectedFirstOption, selectedSecondOption]);

  const firstOptionDropDown = (
    <Dropdown className="app-dropdown">
      <Dropdown.Toggle
        as={isTablet ? btnToggle : firstToggle}
        id="dropdown-basic"
      >
        {selectedFirstOption?.text || firstOptionDefault}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {fOptions.map((op) => (
          <Dropdown.Item
            onClick={() => setSelectedFirstOption(op)}
            key={op.text}
          >
            {op.text}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );

  const secondOptionDropdown = (
    <Dropdown className="app-dropdown">
      <Dropdown.Toggle
        as={isTablet ? btnToggle : secondToggle}
        id="dropdown-basic"
      >
        {selectedSecondOption?.text || secondOptionDefault}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {sOptions.map((op) => (
          <Dropdown.Item
            onClick={() => setSelectedSecondOption(op)}
            key={op.text}
          >
            {op.text}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );

  const searchBtn = (
    <Button
      onClick={() => {
        doHandleSearch();
        if (isTablet) {
          handleModal();
        }
      }}
      block
      style="pink"
    >
      Buscar
    </Button>
  );

  return (
    <div className="search-bar">
      <Modal
        className="search-modal"
        dialogClassName="search-modal-dialog"
        show={showModal}
        onHide={handleModal}
      >
        <button type="button" onClick={handleModal} className="close">
          <span>X</span>
        </button>
        <Title variant="h2">Realiza una busqueda</Title>

        <span className="selector-sm">{firstOptionText}</span>
        {firstOptionDropDown}
        <span className="selector-sm">{secondOptionsText}</span>
        {secondOptionDropdown}

        {searchBtn}
      </Modal>
      {isTablet ? (
        <Row>
          <Col lg="12">
            <InputGroup
              className="mb-1 search-input-mobile"
              onClick={() => setShowModal(!showModal)}
            >
              <InputGroup.Prepend>
                <InputGroup.Text id="buscar">
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                className="shadow-none"
                readOnly
                placeholder="Buscar"
                aria-describedby="basic-addon-search"
              />
            </InputGroup>
          </Col>
        </Row>
      ) : (
        <Card>
          <Row>
            <Col lg={showBtn ? '5' : '6'} md={showBtn ? '5' : '6'}>
              {firstOptionDropDown}
            </Col>
            <Col
              lg={showBtn ? '5' : '6'}
              md={showBtn ? '5' : '6'}
              className="bordered"
            >
              {secondOptionDropdown}
            </Col>
            <Col lg="2" md="2">
              {showBtn && searchBtn}
            </Col>
          </Row>
        </Card>
      )}
      {cleanSearch && (
        <Row>
          <Col className="d-flex justify-content-end">
            <span
              onClick={() => {
                setSelectedFirstOption(undefined);
                setSelectedSecondOption(undefined);
                handleSearch(undefined, undefined, firstKeyName, secondKeyName);
              }}
              className="clean-search mb-2"
            >
              Limpiar búsqueda
            </span>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default SearchBar;
