import './style.scss';
import React from 'react';
import { useCombobox } from 'downshift';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type DropdownProps = {
  data?: string[];
  placeholder?: string;
  className?: string;
  dropdownClassName?: string;
  prependIcon?: IconDefinition | string;
  appendIcon?: IconDefinition | string;
  ariaLabel: string;
  inputClassName?: string;
  disabled?: boolean;
  setSelectedValue: React.Dispatch<React.SetStateAction<string>>;
  onKeyPress?: (e: any) => void;
};

const Dropdown: React.FunctionComponent<DropdownProps> = ({
  data = [],
  placeholder = '',
  dropdownClassName = 'autocomplete-dropdown',
  className = 'autocomplete-container',
  prependIcon,
  appendIcon,
  ariaLabel,
  inputClassName = '',
  disabled = true,
  setSelectedValue,
  onKeyPress,
}: DropdownProps) => {
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items: data,
    onInputValueChange: ({ inputValue }) => {
      setSelectedValue(inputValue ? inputValue : '');
    },
  });

  return (
    <div className={className}>
      <InputGroup
        {...getComboboxProps()}
        {...getToggleButtonProps()}
        // onKeyPress={onKeyPress ? (e: any) => onKeyPress(e) : ''}
      >
        {prependIcon ? (
          <InputGroup.Prepend className="autocomplete-icon">
            {typeof prependIcon == 'object' ? (
              <FontAwesomeIcon icon={prependIcon} />
            ) : (
              <img src={prependIcon} alt="" />
            )}
          </InputGroup.Prepend>
        ) : (
          ''
        )}

        <FormControl
          aria-label={ariaLabel}
          className={`autocomplete-input ${inputClassName}`}
          {...getInputProps()}
          placeholder={placeholder}
          readOnly
          // disabled={disabled}
          // {...getComboboxProps()}
          // {...getToggleButtonProps()}
        />

        {appendIcon ? (
          <InputGroup.Append className="autocomplete-icon">
            {typeof appendIcon == 'object' ? (
              <FontAwesomeIcon icon={appendIcon} />
            ) : (
              <img src={appendIcon} alt="" />
            )}
          </InputGroup.Append>
        ) : (
          ''
        )}
      </InputGroup>
      <ul
        className={`autocomplete-list ${dropdownClassName}`}
        {...getMenuProps()}
      >
        {isOpen &&
          data.map((item, index) => (
            <li
              style={
                highlightedIndex === index ? { backgroundColor: '#bde4ff' } : {}
              }
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              {item}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Dropdown;
