import './style.scss';
import React, { memo } from 'react';

type CalloutProps = {
  orientation?: 'vertical' | 'horizontal';
  className?: string;
  children: any;
  backgroundColor?: string;
};

const Callout: React.FunctionComponent<CalloutProps> = ({
  className = '',
  children,
  backgroundColor = '#fc9905',
}: CalloutProps) => (
  <div style={{ backgroundColor }} className={`callout ${className}`}>
    {children}
  </div>
);

export default memo(Callout);
