import './style.scss';
import React, { memo } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
// import FullBright from '../../../../assets/images/views/home/programs/Fulbright.jpg';
// import Daad from '../../../../assets/images/views/home/programs/2000px-DAAD_Logo.jpg';
// import Chevening from '../../../../assets/images/views/home/programs/Chevening_primary_CMYK-with-bleed.jpg';
// import Oas from '../../../../assets/images/views/home/programs/OAS_Seal_ESP_Principal_.jpg';
// import WorldBank from '../../../../assets/images/views/home/programs/d76a7436747a3ef9e59165c2aab58a07.jpg';
// import Carolina from '../../../../assets/images/views/home/programs/Fundación-Carolina.jpg';
import FullBright from '../../../../assets/images/views/home/programs/logos/fulbright.png';
import Daad from '../../../../assets/images/views/home/programs/logos/daad.png';
import Chevening from '../../../../assets/images/views/home/programs/logos/chevening.png';
import Oas from '../../../../assets/images/views/home/programs/logos/oea.png';
import WorldBank from '../../../../assets/images/views/home/programs/logos/world-bank.png';
import Carolina from '../../../../assets/images/views/home/programs/logos/fundacion-carolina.png';

const ScholarshipPrograms: React.FunctionComponent = () => {
  // const images = [Chevening, Carolina, FullBright, Daad, WorldBank, Oas];
  const images = [Oas, Chevening, FullBright, Carolina, Daad, WorldBank];
  return (
    <section id="scholarships">
      <Container>
        <Row className="programs-row">
          <Col xs={12}>
            <h2 className="our-students-title">
              Nuestros estudiantes han obtenido las mejores becas, ¡en todas
              partes del mundo!
            </h2>
            <div className="divider"></div>
          </Col>

          <Col className="programs-columns" xl="2" lg="2" md="4" sm="6" xs="6">
            <img
              src={images[0]}
              className="scholarship-image"
              alt="Programa de becas"
            />
          </Col>
          <Col className="programs-columns" xl="2" lg="2" md="4" sm="6" xs="6">
            <img
              src={images[1]}
              className="scholarship-image"
              alt="Programa de becas"
            />
          </Col>
          <Col className="programs-columns" xl="2" lg="2" md="4" sm="6" xs="6">
            <img
              src={images[2]}
              className="scholarship-image"
              alt="Programa de becas"
            />
          </Col>
          <Col className="programs-columns" xl="2" lg="2" md="4" sm="6" xs="6">
            <img
              src={images[3]}
              className="scholarship-image"
              alt="Programa de becas"
            />
          </Col>
          <Col className="programs-columns" xl="2" lg="2" md="4" sm="6" xs="6">
            <img
              src={images[4]}
              className="scholarship-image"
              alt="Programa de becas"
            />
          </Col>
          <Col className="programs-columns" xl="2" lg="2" md="4" sm="6" xs="6">
            <img
              src={images[5]}
              className="scholarship-image"
              alt="Programa de becas"
            />
          </Col>
          <Row className="disclaimer-wrapper mt-5" noGutters>
            <div className="disclaimer">
              *infobeca.com no pertenece ni está asociado con estos programas de
              becas o instituciones.
            </div>
          </Row>
          <div className="mt-2 bottom-separator">
            <span className="separator"></span>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default memo(ScholarshipPrograms);
