import './style.scss';
import React, { memo } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HomeInfobecas2 from '../../../../assets/images/views/home/home-infobecas2.svg';
import BaseButton from '../../../../components/Button';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../../routes';

const AboutUs: React.FunctionComponent = () => {
  return (
    <section id="about">
      <Container>
        <Row className="everyone-deserves-row" noGutters>
          <Col xl={6} lg={5} md={12} sm={12}>
            <h4>Sobre nosotros</h4>
            <h2>Todo el mundo merece una buena educación</h2>
            <p>
              No importa la carrera o el país destino, nuestros resultados han
              transformado la vida de muchas familias.
            </p>
            <Link to={ROUTES.about}>
              <BaseButton style="pink">
                Conoce más sobre nosotros
              </BaseButton>
            </Link>
          </Col>

          <Col xl={6} lg={5} md={12} sm={12}>
            <img src={HomeInfobecas2} />
          </Col>

          <Col sm={12} className="num">
            <Row>
              <Col xl={4} lg={4} md={4} sm={12}>
                <h3>+500</h3>
                <p className="p2">estudiantes asistidos</p>
              </Col>
              <Col xl={4} lg={4} md={4} sm={12}>
                <h3>USD$10M</h3>
                <p className="p2">en becas otorgadas a nuestros estudiantes</p>
              </Col>
              <Col xl={4} lg={4} md={4} sm={12}>
                <h3>+50</h3>
                <p className="p2">destinos académicos en el mundo</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default memo(AboutUs);
