import './style.scss';
import React, { memo } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

type ComboBoxProps = {
  fluid?: boolean;
  imagePosition?: 'left' | 'right';
  leftColBreakpoints?: any;
  rightColBreakPoints?: any;
  img: string;
  alt?: string;
  children: string | JSX.Element | JSX.Element[] | any;
  className?: string | undefined;
  imgClassName?: string | undefined;
  rightColClassname?: string | undefined;
  leftColClassname?: string | undefined;
  rowClassName?: string | undefined;
  container?: boolean;
  backgroundColor?: string;
  imageChildren?: JSX.Element;
  gutters?: boolean;
};

const ComboBox: React.FunctionComponent<ComboBoxProps> = ({
  fluid = true,
  imagePosition = 'left',
  leftColBreakpoints = [12, 6, 6],
  rightColBreakPoints = [12, 6, 6],
  img,
  alt = '',
  children,
  className = '',
  imgClassName = '',
  rightColClassname = '',
  leftColClassname = '',
  rowClassName = '',
  container = true,
  backgroundColor = '',
  imageChildren,
  gutters=true
}: ComboBoxProps) => {
  const content = () => (
    <Row noGutters={gutters} className={rowClassName}>
      {imagePosition == 'left' ? (
        <>
          <Col
            sm={leftColBreakpoints[0]}
            md={leftColBreakpoints[1]}
            lg={leftColBreakpoints[2]}
            className={leftColClassname}
            style={{ backgroundColor: backgroundColor }}
          >
            <img src={img} alt={alt} className={imgClassName} />
            {imageChildren ? imageChildren : ''}
          </Col>
          <Col
            sm={rightColBreakPoints[0]}
            md={rightColBreakPoints[1]}
            lg={rightColBreakPoints[2]}
            className={rightColClassname}
            style={{ backgroundColor: backgroundColor }}
          >
            {children}
          </Col>
        </>
      ) : (
        <>
          <Col
            sm={leftColBreakpoints[0]}
            md={leftColBreakpoints[1]}
            lg={leftColBreakpoints[2]}
            className={leftColClassname}
            style={{ backgroundColor: backgroundColor }}
          >
            {children}
          </Col>
          <Col
            sm={rightColBreakPoints[0]}
            md={rightColBreakPoints[1]}
            lg={rightColBreakPoints[2]}
            className={rightColClassname}
            style={{ backgroundColor: backgroundColor }}
          >
            <img src={img} alt={alt} className={imgClassName} />
            {imageChildren ? imageChildren : ''}
          </Col>
        </>
      )}
    </Row>
  );

  return (
    <>
      {container ? (
        <Container fluid={fluid} className={className}>
          {content()}
        </Container>
      ) : (
        content()
      )}
    </>
  );
};

export default ComboBox;
