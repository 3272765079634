import React, { useState, useContext, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ScholarshipCard from '../../components/Card/ScholarshipCard';
import { Scholarship } from '../../models/index';
import truncate from 'lodash/truncate';
import UserContext from '../../UserContext';
import Scholarships from '../../Services/Scholarships';
import SearchNoRegisterService from '../../Services/SearchNoRegister';
import { useHistory } from 'react-router-dom';
import { SEARCH_LIMIT, DAYS_LIMIT } from '../../constants';
import { getDateFromString } from '../../Utils';
import { ROUTES } from '../../routes';
import SignupModal from '../../components/Modal/SignUpModal';
import has from 'lodash/has';

export const generateSeparator = () => (
  <div id="separator-wrapper" className="mb-5">
    <div id="separator"></div>
  </div>
);
