import axios from 'axios';
import BaseService from '../Base/index';
import { ScholarshipTypePromise, ScholarshipFilters } from '../../models';
import { PAGINATION_SIZE } from '../../constants';
import { filterUndefinedFields } from '../../Utils';

class ScholarshipService extends BaseService {
  constructor() {
    super('becas');
  }

  private _calculatePagination(page: number) {
    return (
      (page === 1 ? page : page - 1) * (page === 1 ? 1 : 10) +
      (page === 1 ? 0 : 6) -
      1
    );
  }

  async getAllScholarships(): Promise<ScholarshipTypePromise> {
    try {
      const { data } = await axios.get(this._getURL(''));
      return { data };
    } catch (err) {
      return { error: err };
    }
  }

  async getScholarship(id: string): Promise<ScholarshipTypePromise> {
    try {
      const { data } = await axios.get(this._getURL(`${id}`));
      return { data: data[0] };
    } catch (err) {
      return { error: err };
    }
  }

  async getScholarshipsPaginated(
    page: number = 1,
    quantity: number = 10,
  ): Promise<ScholarshipTypePromise> {
    const pagination = this._calculatePagination(page);
    const body = {
      page: pagination,
      cantidad: quantity,
    };

    try {
      const { data } = await axios.post(
        this._getURL(`page/${pagination}`),
        body,
      );

      return { data };
    } catch (err) {
      return { error: err };
    }
  }

  // Returns a string of saved scholarships
  async saveOrDeleteScholarship(
    user_id: string,
    scholarship_id: string,
  ): Promise<string[]> {
    const body = {
      id: user_id,
      id_beca: scholarship_id,
    };

    try {
      const { data } = await axios.post(
        `${this._baseURL}/dashboard/modificar_beca_conjunto`,
        body,
      );
      return data[0].becas_guardadas as string[];
    } catch {
      return [];
    }
  }

  async getSavedScholarships(user_id: string): Promise<string[]> {
    try {
      const { data } = await axios.get(`${this._baseURL}/dashboard/${user_id}`);

      return data[0].becas_guardadas;
    } catch {
      return [];
    }
  }

  async getRecommendedScholarships(
    user_id: string,
    page: number = 1,
    quantity: number = 10,
  ): Promise<ScholarshipTypePromise> {
    const body = {
      id: user_id,
      pagina: page,
      cantidad: quantity,
    };

    try {
      const { data } = await axios.post(
        `${this._baseURL}/recomendaciones`,
        body,
      );
      return { data: data };
    } catch (error) {
      return { error };
    }
  }

  async getRecentScholarships(
    page: number = 1,
    quantity: number = 10,
  ): Promise<ScholarshipTypePromise> {
    const pagination = this._calculatePagination(page);

    const body = {
      page: pagination,
      cantidad: quantity,
    };

    try {
      const { data } = await axios.post(this._getURL('recientes'), body);
      return { data: data };
    } catch (error) {
      return { error };
    }
  }

  async getFilterInputs(): Promise<ScholarshipFilters> {
    const empty_fields: ScholarshipFilters = {
      nivel_estudios: [],
      area_estudio: [],
      duracion: [],
      pais: [],
      idiomas: [],
    };

    try {
      const { data } = await axios.get(`${this._baseURL}/get_inputs/`);

      const form: ScholarshipFilters = data[0];

      // Sort fields
      Object.keys(form).map((key) => (form[key] = form[key].sort()));

      return form as ScholarshipFilters;
    } catch {
      return empty_fields;
    }
  }

  async filterScholarships(
    page: number = 1,
    quantity: number = PAGINATION_SIZE,
    options: ScholarshipFilters,
  ): Promise<ScholarshipTypePromise> {
    const filteredOptions = filterUndefinedFields(
      options,
    ) as ScholarshipFilters;

    const pagination = this._calculatePagination(page);
    const body = {
      pagina: pagination,
      cantidad: quantity,
      ...filteredOptions,
    };

    try {
      const { data } = await axios.post(`${this._baseURL}/filtro_becas/`, body);

      const total = data.pop().cantidad_encontrada;

      return { data, quantity: total };
    } catch (error) {
      return { error };
    }
  }

  async filterScholarshipCustom(
    user_id: string,
    page: number = 1,
    quantity: number = PAGINATION_SIZE,
    options: ScholarshipFilters,
    type: 'recommended' | 'saved',
  ): Promise<ScholarshipTypePromise> {
    const pagination = this._calculatePagination(page);
    const body = {
      id: user_id,
      pagina: pagination >= 0 ? pagination : 0,
      cantidad: quantity,
      ...options,
    };

    const typeMap = {
      recommended: 'recomendadas',
      saved: 'guardadas',
    };

    try {
      const { data } = await axios.post(
        `${this._baseURL}/filtro_becas/${typeMap[type]}`,
        body,
      );

      const total = data.pop().cantidad_encontrada;

      return { data, quantity: total };
    } catch (error) {
      return { error: error.response.data };
    }
  }

  async subscribeEmailToNewsLetter(email: string): Promise<boolean> {
    const body = {
      correo_electronico: email,
    };

    try {
      await axios.post(`${this._baseURL}/newsletter_signup`, body);
      return true;
    } catch {
      return false;
    }
  }

  async checkIfSubscribedToNewsLetter(email: string): Promise<boolean> {
    const body = {
      correo_electronico: email,
    };

    try {
      const {
        data: { suscribed },
      } = await axios.post(`${this._baseURL}/newsletter`, body);

      return suscribed;
    } catch {
      return false;
    }
  }
}

export default new ScholarshipService();
