import './style.scss';
import React, { useState, useEffect, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  Option,
  PAGINATION_SIZE,
  FIRST_FILTER,
  SECOND_FILTER,
  OMIT_FILTERS,
  LG_BREAKPOINT,
} from '../../constants';
import SearchBar from '../../components/SearchBar/index';
import Button from '../../components/Button';
import SignUpModal from '../../components/Modal/SignUpModal';
import Subscribe from '../../components/ComboBox/Subscribe';
import { useWindowWidth } from '@react-hook/window-size';
import FiltersModal from '../../components/FiltersModal';
import FilterIcon from '../../assets/icons/filter.svg';
import BaseTabs from '../../components/Tabs';
import {
  ScholarshipFiltersProps,
  ScholarshipFilters,
  Scholarship,
} from '../../models';
import { FunctionComponentPage } from '../function-component-page';
import { match as Match, useLocation } from 'react-router-dom';
import Scholarships from '../../Services/Scholarships';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import { filterFilters } from '../../Utils';
import PuffLoader from 'react-spinners/PuffLoader';
import ClipLoader from 'react-spinners/ClipLoader';
import { map_filters } from '../Scholarships/utils';
import NoResults from '../../components/NoResults/NoResults';
import GenerateCardsRow from '../../components/GenerateCardRow/index';
import UserContext from '../../UserContext';
import Users from '../../Services/Users';
import CompleteProfile from '../../components/CompleteProfile';
import { scrollToTop } from '../../Utils/index';
import UpIcon from '../../assets/icons/up.svg';

const Search: FunctionComponentPage<ScholarshipFiltersProps> = ({
  data: filters,
}: ScholarshipFiltersProps) => {
  const [quantity, setQuantity] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  // Doesn't matter if it's true or false, the point is to trigger a change
  // to load a new set of scholarship filters
  const [triggerFilter, setTriggerFilter] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [signUpModal, setSignUpModal] = useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [completedProfile, setCompletedProfile] = useState<boolean>(false);
  const [isTablet, setIsTablet] = useState<boolean>(false);
  const [loadingAllSc, setLoadingAllSc] = useState<boolean>(false);
  const [loadingNext, setLoadingNext] = useState<boolean>(false);
  const [searchBarFirstOp, setSearchBarFirstOp] = useState<Option>();
  const [searchBarSecondOp, setSearchBarSecondOp] = useState<Option>();
  const [scholarships, setScholarships] = useState<Scholarship[]>([]);
  const [filter, setFilter] = useState<ScholarshipFilters>({
    nivel_estudios: [],
    area_estudios: [],
    duracion: [],
    pais: [],
    idiomas: [],
  });

  const options = filterFilters(omit(filters, OMIT_FILTERS));
  const [optionsKeys] = useState(Object.entries(options));

  const width = useWindowWidth();
  const { state: filterState } = useLocation<ScholarshipFilters>();
  const user = useContext(UserContext);

  const loadScholarships = async (manualFilter?: ScholarshipFilters) => {
    setScholarships([]);
    setLoadingAllSc(true);

    const { data: sc, quantity } = await Scholarships.filterScholarships(
      page,
      PAGINATION_SIZE,
      manualFilter ? manualFilter : filter,
    );
    const scCast = sc ? (sc as Scholarship[]) : [];

    setQuantity(quantity ? quantity : 0);
    setScholarships(orderBy(scCast, ['prioridad', 'nombre'], ['desc', 'asc']));
    setHasMore(scCast.length >= PAGINATION_SIZE);
    setLoadingAllSc(false);
  };

  const loadNextPage = async () => {
    setLoadingNext(true);

    const nextPage = page + 1;
    const { data: sc } = await Scholarships.filterScholarships(
      nextPage,
      PAGINATION_SIZE,
      filter,
    );
    const scCast = sc as Scholarship[];
    setScholarships([...scholarships, ...scCast]);
    setPage(page + 1);
    setHasMore(scCast.length >= PAGINATION_SIZE);
    setLoadingNext(false);
  };

  const handleSearch = (
    first: Option | undefined,
    second: Option | undefined,
    firstKeyName: string | undefined,
    secondKeyName: string | undefined,
  ) => {
    if (mounted && !loadingAllSc) {
      const newFilter = {
        ...filter,
        [firstKeyName!]: first ? first.dataField : [],
        [secondKeyName!]: second ? second.dataField : [],
      };

      setFilter({ ...filter, ...newFilter });
      loadScholarships({ ...filter, ...newFilter });
    }
  };

  const handleCheck = (
    selectedOptions: Option[],
    _: Option | undefined,
    keyName: string,
  ) => {
    const newFilter = {
      ...filter,
      [keyName!]: selectedOptions.map((op) => op.dataField),
    };

    setFilter({ ...filter, ...newFilter });

    loadScholarships({ ...filter, ...newFilter });
  };

  const handleModal = () => {
    setTriggerFilter(!triggerFilter);
    setShowModal(!showModal);
  };
  const handleSignUpModal = () => setSignUpModal(!signUpModal);

  useEffect(() => {
    if (filterState && isEmpty(scholarships)) {
      const searchBarOption = Object.assign({}, filterState);
      const filterSearchBar = filterFilters(searchBarOption);

      if (FIRST_FILTER in filterSearchBar) {
        setSearchBarFirstOp(filterSearchBar[FIRST_FILTER][0] as Option);
      }

      if (SECOND_FILTER in filterSearchBar) {
        setSearchBarSecondOp(filterSearchBar[SECOND_FILTER][0] as Option);
      }

      setFilter(filterState);
      loadScholarships(filterState);
    } else {
      loadScholarships();
    }
    setMounted(true);
  }, []);

  useEffect(() => {
    setIsTablet(width <= LG_BREAKPOINT);
  }, [width]);

  useEffect(() => {
    if (mounted) {
      loadScholarships();
    }
  }, [triggerFilter]);

  useEffect(() => {
    if (loggedIn) {
      const checkProfileComplete = async () => {
        setCompletedProfile(await Users.hasCompletedRegisterForm());
      };
      checkProfileComplete();
    }
  }, [loggedIn]);

  useEffect(() => {
    if (user.ready) {
      if (user.data !== undefined) {
        setLoggedIn(true);
      } else {
      }
    }
  }, [user]);

  return (
    <>
      <BaseTabs fixed active={3} isLoggedin={loggedIn} />
      <Container id="search" className="mb-4">
        <FiltersModal
          cleanSearch={handleCheck}
          show={showModal}
          onHide={handleModal}
        >
          {optionsKeys.map(map_filters(handleCheck))}
        </FiltersModal>
        <SignUpModal
          exceededLimit={true}
          show={signUpModal}
          onHide={handleSignUpModal}
        />
        <Row className="filter-page-margin">
          <Col>
            <SearchBar
              showBtn={false}
              defaultFirstSelected={searchBarFirstOp}
              defaultSecondSelected={searchBarSecondOp}
              handleSearch={handleSearch}
            />
            {page > 1 && (
              <img
                src={UpIcon}
                onClick={() => {
                  scrollToTop();
                }}
                className="goToTop"
              />
            )}
            {loggedIn && !completedProfile && (
              <CompleteProfile className="mt-2 mb-4" />
            )}
          </Col>
        </Row>

        <Row className="content">
          {!isTablet && (
            <Col lg="3">{optionsKeys.map(map_filters(handleCheck))}</Col>
          )}
          <Col className="mt-1" lg="9">
            {quantity <= 0 && <NoResults />}

            <div className={quantity <= 0 ? 'd-none' : ''}>
              <div className="d-flex justify-content-between">
                <span className="results">
                  {quantity} resultados encontrados
                </span>
                {isTablet && (
                  <span onClick={handleModal} className="results clickeable">
                    Filtrar <img src={FilterIcon} alt="icono de filtrar" />{' '}
                  </span>
                )}
              </div>
              <hr />
              {loadingAllSc && (
                <div className="puff-loader">
                  <PuffLoader size={150} loading={loadingAllSc} />
                </div>
              )}
              <GenerateCardsRow
                registerCallout={true}
                data={scholarships}
                setSignupModal={setSignUpModal}
              />
              {hasMore && (
                <Row>
                  <Col lg="12">
                    <Button
                      className={loadingNext ? 'loading-btn' : ''}
                      disabled={loadingNext}
                      onClick={loadNextPage}
                      block
                      style="pink"
                    >
                      {loadingNext ? (
                        <div className="clip-loader">
                          <ClipLoader size={28} loading={loadingNext} />
                        </div>
                      ) : (
                        'Ver más'
                      )}
                    </Button>
                  </Col>
                </Row>
              )}

              <Subscribe
                backgroundColor="purple"
                leftColBreakPoints={[7, 7, 7]}
                rightColBreakPoins={[12, 12, 5]}
                isHome={false}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const getInitialProps = async ({
  req,
  res,
  match,
}: {
  req: Request;
  match: Match<any>;
  res: Response;
}): Promise<ScholarshipFiltersProps> => {
  const filters = await Scholarships.getFilterInputs();
  return { data: filters };
};

Search.getInitialProps = getInitialProps;

export default Search;
