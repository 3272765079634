import './style.scss';
import React, { memo } from 'react';
import Modal from 'react-bootstrap/Modal';

type BaseModalProps = {
  className?: string;
  show: boolean;
  onHide: any;
  closeButton?: boolean;
  children: any;
  hideLabel?: string;
};

const BaseModal: React.FunctionComponent<BaseModalProps> = ({
  className = '',
  show,
  closeButton = true,
  onHide,
  hideLabel = 'Cerrar',
  children,
}: BaseModalProps) => (
  <Modal
    keyboard={closeButton}
    className="base-modal"
    dialogClassName={`base-modal-dialog ${className}`}
    show={show}
    onHide={onHide}
  >
    {closeButton && (
      <button type="button" onClick={onHide} className="close">
        <span>{hideLabel}</span>
      </button>
    )}

    {children}
  </Modal>
);

export default memo(BaseModal);
