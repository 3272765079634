import React, { memo } from 'react';
import Callout from '..';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Title from '../../Title';
import Button from '../../Button';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../routes';

type RegisterCalloutProps = {
  onClick?: () => void;
};

const RegisterCallout: React.FunctionComponent<RegisterCalloutProps> = ({
  onClick = undefined,
}) => {
  const history = useHistory();

  return (
    <Callout className="mb-4">
      <Row>
        <Col lg="8" className="d-flex align-items-center">
          <Title size="1em" align="left" color="white" variant="h3">
            Destaca tu perfil académico con una beca internacional.
          </Title>
        </Col>
        <Col lg="4">
          <Button
            onClick={
              onClick
                ? onClick
                : () => {
                    history.push(ROUTES.signin);
                  }
            }
            block
            variant="outline-secondary"
          >
            Regístrate gratis
          </Button>
        </Col>
      </Row>
    </Callout>
  );
};

export default memo(RegisterCallout);
