import './style.scss';
import React, { useEffect, useContext, useState } from 'react';
import BaseTabs from '../../../components/Tabs';
import SearchBar from '../../../components/SearchBar';
import { handleSearchRedirect } from '../utils';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CompleteProfile from '../../../components/CompleteProfile';
import Title from '../../../components/Title';
import { Link } from 'react-router-dom';
import NotSavedImage from '../../../assets/icons/not-saved.svg';
import New from '../../../assets/icons/nuevos.svg';
import Recommendations from '../../../assets/icons/recomendaciones.svg';
import { generateSeparator } from '../index';
import { Scholarship } from '../../../models';
import { useHistory } from 'react-router-dom';
import UserContext from '../../../UserContext';
import { ROUTES } from '../../../routes';
import Users from '../../../Services/Users';
import Scholarships from '../../../Services/Scholarships';
import GenerateCardsRow from '../../../components/GenerateCardRow/index';
import SignUpModal from '../../../components/Modal/SignUpModal';
import Button from '../../../components/Button';
import Callout from '../../../components/Callout';
import NoScholarshipIcon from '../../../assets/icons/no-scholarship.svg';
import { useWindowWidth } from '@react-hook/window-size';
import { LG_BREAKPOINT, SM_BREAKPOINT } from '../../../constants';

const MAX_SC_PER_SESSION = 6;

const ScholarshipsStart: React.FunctionComponent = () => {
  const [loginModal, setLoginModal] = useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [recommended, setRecommended] = useState<Scholarship[]>([]);
  const [saved, setSaved] = useState<Scholarship[]>([]);
  const [recent, setRecent] = useState<Scholarship[]>([]);
  const [completedProfile, setCompletedProfile] = useState<boolean>(false);
  const [updateSaved, setUpdatedSaved] = useState<boolean>(true);

  const user = useContext(UserContext);

  const getSavedScholarships = async (
    user_id: string,
    scholarships?: string[],
  ) => {
    const sc = await Scholarships.filterScholarshipCustom(
      user_id,
      0,
      MAX_SC_PER_SESSION,
      {},
      'saved',
    );

    if (sc.error === undefined) {
      const scCast = sc.data as Scholarship[];
      setSaved(scCast);
    }
  };

  useEffect(() => {
    if (user.ready) {
      if (user.data !== undefined) {
        setLoggedIn(true);
      } else {
        setLoginModal(true);
      }
    }
  }, [user]);

  const width = useWindowWidth();

  const [isTablet, setIsTablet] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    setIsTablet(width <= LG_BREAKPOINT);
    setIsMobile(width <= SM_BREAKPOINT);
  }, [width]);

  useEffect(() => {
    if (loggedIn) {
      const user_id = user.data!.id_usuario;

      const getRecommendedScholarships = async (user_id: string) => {
        const recommended = await Scholarships.filterScholarshipCustom(
          user_id,
          1,
          MAX_SC_PER_SESSION,
          {},
          'recommended',
        );
        if (recommended.data) {
          const scholarships = recommended.data as Scholarship[];
          setRecommended(scholarships);

          if (scholarships.length == 6) setUpdatedSaved(false);
          else setUpdatedSaved(true);
        }
      };

      const getRecentScholarships = async () => {
        const recent = await Scholarships.getRecentScholarships(
          1,
          MAX_SC_PER_SESSION,
        );
        if (recent.data) {
          const scholarships = recent.data as Scholarship[];
          setRecent(scholarships);
        }
      };

      const checkProfileComplete = async () =>
        setCompletedProfile(await Users.hasCompletedRegisterForm());

      // Load all the data
      getSavedScholarships(user_id);
      getRecommendedScholarships(user_id);
      getRecentScholarships();
      checkProfileComplete();
    }
  }, [loggedIn]);

  const onAfterSave = (scholarships: string[]) => {
    getSavedScholarships(user.data!.id_usuario, scholarships);
  };

  const history = useHistory();
  const doHandleSearchRedirect = handleSearchRedirect(history);

  const moreRecommended = recommended.length >= MAX_SC_PER_SESSION;
  const moreSaved = saved && saved.length >= MAX_SC_PER_SESSION;

  return (
    <div id="scholarships-start-container">
      <SignUpModal
        show={loginModal}
        onHide={() => {
          history.push(ROUTES.search);
        }}
      />
      <BaseTabs fixed className="mb-4" active={0} />

      <Container className="filter-page-margin">
        <SearchBar handleSearch={doHandleSearchRedirect} />
        <div id="scholarships-content-container">
          {loggedIn && !completedProfile && (
            <CompleteProfile className="mt-2 mb-4" />
          )}
          <Row className="mb-4 title-row">
            <Col sm="12" md="6" className="d-flex align-items-start">
              <img
                src={Recommendations}
                className="title-icon"
                alt="becas recomendadas"
              />
              <Title className="ml-3" variant="h3">
                Recomendaciones {isMobile && moreRecommended && <br />} para ti
              </Title>
            </Col>
            {moreRecommended && (
              <Col sm="12" md="6" className="d-flex justify-content-end ">
                <Link
                  to={ROUTES.scholarshipsRecommended}
                  className="title-link"
                >
                  Ver más
                </Link>
              </Col>
            )}
          </Row>
          {recommended.length > 0 ? (
            <GenerateCardsRow
              data={recommended}
              onAfterSave={onAfterSave}
              savedScholarships={saved}
            />
          ) : (
            <Callout backgroundColor="#384696" className="mb-4">
              <Row>
                <Col lg="8" className="d-flex align-items-center">
                  <Title size="1em" align="left" color="white" variant="h3">
                    ¡Lo sentimos! No encontramos recomendaciones para ti.
                    Intenta ajustar tu perfil para poder encontrar posibilidades
                    para ti.
                  </Title>
                </Col>
                <Col lg="4">
                  <Button
                    block
                    variant="outline-secondary"
                    onClick={() => history.push(ROUTES.profile_edit)}
                  >
                    Ajusta tu perfil
                  </Button>
                </Col>
              </Row>
            </Callout>
          )}
          {generateSeparator()}
          <Row className="mb-4  title-row">
            <Col sm="12" md="6" className="d-flex align-items-start">
              <img
                src={NotSavedImage}
                className="title-icon"
                alt="becas guardadas"
              />
              <Title className="ml-3" variant="h3">
                Becas guardadas
              </Title>
            </Col>
            {moreSaved && (
              <Col sm="12" md="6" className="d-flex justify-content-end ">
                <Link to={ROUTES.scholarshipsSaved} className="title-link">
                  Ver todas
                </Link>
              </Col>
            )}
          </Row>
          {saved.length > 0 ? (
            <GenerateCardsRow
              data={saved}
              onAfterSave={onAfterSave}
              savedScholarships={saved}
            />
          ) : (
            <Callout
              backgroundColor="#2b73b6"
              className="mb-4 no-saved-callout"
            >
              <Row>
                <Col lg="8" className="d-flex align-items-center">
                  <Title size="1em" align="left" color="white" variant="h3">
                    Aún no tienes becas guardadas. Empieza ya a almacenar todas
                    las que desees y así tenerlas siempre disponibles.
                  </Title>
                </Col>
                {!isTablet && (
                  <Col lg="4" className="img">
                    <img src={NoScholarshipIcon} />
                  </Col>
                )}
              </Row>
            </Callout>
          )}
          {generateSeparator()}
          <Row className="mb-4 title-row">
            <Col sm="12" md="6" className="d-flex align-items-start">
              <img src={New} className="title-icon" alt="nuevas becas" />{' '}
              <Title className="ml-3" variant="h3">
                Programas más {isMobile && <br />}
                recientes
              </Title>
            </Col>
            <Col sm="12" md="6" className="d-flex justify-content-end ">
              <Link to={ROUTES.search} className="title-link">
                Ver todas
              </Link>
            </Col>
          </Row>
          <GenerateCardsRow
            data={recent}
            onAfterSave={onAfterSave}
            savedScholarships={saved}
          />
        </div>
      </Container>
    </div>
  );
};

export default ScholarshipsStart;
