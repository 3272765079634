import './style.scss';
import React, { useState, useEffect, useContext } from 'react';
import truncate from 'lodash/truncate';
import BaseCard from '../index';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import SaveImage from '../../../assets/icons/saved.svg';
import NotSavedImage from '../../../assets/icons/not-saved.svg';
import Clock from '../../../assets/icons/clock.svg';
import UserContext from '../../../UserContext';
import SignUpModal from '../../Modal/SignUpModal';
import Scholarships from '../../../Services/Scholarships';

type ScholarshipCardProps = {
  data: any;
  saved?: boolean;
  link?: string;
  id?: string;
  onClick?: (id: string) => void;
  onAfterSave?: (scholarships: string[]) => void;
};

const ScholarshipCard: React.FunctionComponent<ScholarshipCardProps> = ({
  data = {
    country: 'Estados Unidos',
    days_left: '123 days left',
  },
  saved = false,
  onClick,
  onAfterSave,
}: ScholarshipCardProps) => {
  const [isSaved, setIsSaved] = useState<boolean>(saved);
  const [modal, setModal] = useState<boolean>(false);
  const [afterChangeArr, setAfterChangeArr] = useState<string[]>();

  useEffect(() => {
    setIsSaved(saved);
  }, [saved]);

  const user = useContext(UserContext);

  const handleOnClick = async () => {
    if (user.ready && user.data !== undefined) {
      const saveOrDelete = async () => {
        const user_id = user.data?.id_usuario!;
        const id = data.id;
        return await Scholarships.saveOrDeleteScholarship(user_id, id);
      };

      const newScArr = await saveOrDelete();

      if (newScArr !== undefined) {
        setAfterChangeArr(newScArr);
        setIsSaved(!isSaved);
      }
    } else if (user.ready && user.data === undefined) {
      setModal(true);
    }
  };

  useEffect(() => {
    if (onAfterSave && afterChangeArr !== undefined) {
      onAfterSave(afterChangeArr!);
    }
  }, [afterChangeArr]);

  return (
    <>
      <SignUpModal show={modal} onHide={() => setModal(!modal)} />
      <BaseCard
        className={`p-3 scholarship-card ${
          isSaved ? 'saved-card' : 'not-saved-card'
        }`}
      >
        <Card.Title>
          <Row noGutters>
            <Col xs={9}>
              <div className="scholarship-country">
                {truncate(data.country, { length: 65, separator: '...' })}
              </div>
            </Col>
            <Col xs={3}>
              {isSaved ? (
                <img
                  src={SaveImage}
                  alt="Beca guardada"
                  onClick={handleOnClick}
                />
              ) : (
                <img
                  src={NotSavedImage}
                  alt="Beca no guardada"
                  onClick={handleOnClick}
                />
              )}
            </Col>
          </Row>
        </Card.Title>
        <div
          className="link"
          onClick={onClick ? () => onClick(data.id) : undefined}
        >
          <div>
            <Card.Subtitle>{data.university}</Card.Subtitle>
            <Card.Text className="mt-3">{data.text}</Card.Text>
          </div>
          <Card.Footer>
            <div className="splitter mb-3"></div>
            <img className="mb-1 mr-2" src={Clock} alt="clock" />
            {truncate(data.days_left, { length: 85, separator: '...' })}
          </Card.Footer>
        </div>
      </BaseCard>
    </>
  );
};

export default ScholarshipCard;
