import './style.scss';
import React, { memo } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Why1 from '../../../../assets/images/views/home/why1.svg';
import Why2 from '../../../../assets/images/views/home/why2.svg';
import Why3 from '../../../../assets/images/views/home/why3.svg';
import Container from 'react-bootstrap/Container';

const Why: React.FunctionComponent = () => {
  return (
    <section id="process">
      <Container>
        <Row className="process-row">
          <Col sm={12}>
            <h2 className="process-title">
              El proceso de aplicar a una beca puede ser muy estresante y
              confuso para cualquier persona.
            </h2>
            <h4 id="process-before-text">
              Dentro de los principales obstáculos, encontramos:
            </h4>
          </Col>

          <Col xl={4} lg={4} md={12} sm={12} className="steps">
            <img src={Why1} alt="Busqueda de programas" />
            <div className="divider"></div>
            <h3>No saber por dónde empezar a buscar programas</h3>
          </Col>

          <Col xl={4} lg={4} md={12} sm={12} className="steps">
            <img src={Why2} alt="opciones de becas" />
            <div className="divider"></div>
            <h3>
              No conocer las opciones de becas según sus perfiles académicos
            </h3>
          </Col>

          <Col xl={4} lg={4} md={12} sm={12} className="steps">
            <img src={Why3} alt="Pasos para oportunidades" />
            <div className="divider"></div>
            <h3>No saber los pasos para aprovechar estas oportunidades</h3>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default memo(Why);
