import './style.scss';
import React, { memo } from 'react';
import Modal from '../.';
import Title from '../../Title';
import Button from '../../Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PersonComputer from '../../../assets/images/commons/person-using-computer.svg';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../routes';

type SignUpModalProps = {
  show: boolean;
  onHide?: any;
  exceededLimit?: boolean;
  closeButton?: boolean;
};

const SignUpModal: React.FunctionComponent<SignUpModalProps> = ({
  show,
  onHide,
  exceededLimit = false,
  closeButton = true,
}: SignUpModalProps) => {
  return (
    <Modal
      className="sign-up-modal"
      closeButton={closeButton}
      show={show}
      onHide={onHide}
    >
      <Container>
        <Row>
          <Col sm="12" md={12} lg={6} className="img-col">
            <img src={PersonComputer} />
          </Col>
          <Col sm="12" md={12} lg={6} className="content">
            <Title variant="h1" align="left">
              {!exceededLimit
                ? 'Regístrate y obtén acceso inmediato a cientos de programas en todo el mundo.'
                : '¡Ya excediste tus 5 búsquedas mensuales! Registrate ya y busca de manera ilimitada'}
            </Title>
            <p>
              No te tomará mucho tiempo. Solo debes seguir unos simples pasos y
              listo.
            </p>
            <Link to={ROUTES.signin}>
              <Button className="pr-4 pl-4" style="pink">
                Regístrarme
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default memo(SignUpModal);
